/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');


// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

require("@fortawesome/fontawesome-free/js/all.js");

// any CSS you require will output into a single css file (app.css in this case)
require('@fortawesome/fontawesome-free/css/all.css');
require('../css/app.scss');

//images
require('../img/deco-left.svg');
require('../img/deco-right.svg');
require('../img/logo-favicon.png');
require('../img/desk.jpg')

jQuery( document ).ready(function( $ ) {
    /** Deal with navbar on the left side **/
    var previousPageContentPaddingLeft= $(".page-content").css("padding-left");
    $("#collapsibleNavbar").on("show.bs.collapse",function(event){
        if($(event.target).is((this))){
            previousPageContentPaddingLeft = $(".page-content").css("padding-left");
            let marginRight = 20;
            let nw = $("nav#collapsibleNavbar").outerWidth();
            // $(this).css('width',previousPageContentPaddingLeft).animate({
                // width:w
            // });
            let pw = $(".page-content").outerWidth();
            let ww = $(window).width()
            console.log(pw, ww);
            if (nw-0.5*(ww-pw) > 0){
                $(".page-content").animate({
                    "padding-left" : nw-0.5*(ww-pw) + marginRight//previously : nw+marginRight
                },200);
            }
        }
    });

    $("#collapsibleNavbar").on("hidden.bs.collapse",function(event){
        if($(event.target).is($(this))){
            $(".page-content").animate({
                "padding-left" : previousPageContentPaddingLeft
            },500);
        }
    });
    /** End of navbar animation **/

});
